/* PSPricer Component Style Sheet */
.pricer-content {
    width: 100%;
}

.pricer-content .pricer-buttons {
    margin-bottom: 15px;
}

.pricer-content .pricer-panel {
    padding: 10px;
    border: 1px solid rgb(226, 226, 226);
    height: 80vh;
}

.pricer-panel .pricer-structure-panel {
    text-align: center;
    height: 35vh;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    overflow-x: auto;
}

.pricer-structure-panel .structure-title {
    text-align: left;
    background: #007fed;
    padding: 1px 10px;
    width: 130px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.pricer-structure-panel .structure-title span {
    color: white;
}

.pricer-panel .pricer-result-panel {
    margin-top: 20px;
    text-align: right;
    height: 40vh;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    overflow-x: auto;
}

.pricer-result-panel .result-title {
    text-align: left;
    background: #007fed;
    padding: 1px 10px;
    width: 120px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.pricer-result-panel .result-title span {
    color: white;
}