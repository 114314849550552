.userManage{
    padding: 30px;
}
.userManage .registerPanel{
    padding: 20px;
}
.userManage .registerPanel h3, .permissionPanel h3, .userList .ant-table-title{
    font-size: 16px;
    font-weight: bold;
    color: #117fed;
    margin-bottom: 20px;
}
.userManage .registerPanel label{
    font-size: 12px;
    font-weight: normal;
}
.userManage .registerUserBtnGroup{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.userManage .registerPanel .ant-col-12{
    margin-bottom: 15px;;
}
.userManage.site-card-border-less-wrapper .ant-table-tbody>tr.ant-table-row:hover>td{
    background: none;
    color: black;
}
.userManage .userSave{
    float: right;
    margin: 20px 0 0;
}
.userManage .permissionPanel .ant-checkbox-wrapper{
    margin-bottom: 15px;
    font-weight: normal;
}
.userManage .statusLabel{
    margin-right: 20px;
    font-weight: bold;
    color: #117fed;
    font-size: 16px;
}
.userManage .registerPanelHeader{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.userManage .ant-table-content{
    overflow-x: scroll;
}
@media only screen and (max-width: 600px) {
    .userManage {
        padding: 0;
        }
    .userManage .registerPanel{
        padding: 0;
    }
  }