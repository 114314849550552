/* PSSettleTable Component Style Sheets */

.settlement-feature-item {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.settlement-feature-item .settlement-feature-title {
    background: #007fed;
    color: white;
    padding: 5px 10px;
}

.settlement-feature-item .table-row td {
    padding: 0;
}