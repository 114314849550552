/* PSNaveBar components Styles */
nav.navbar {
    font-size: 15px;
    margin-bottom: 0px;
    -webkit-box-shadow: 0px 5px 9px 0px rgba(173, 173, 173, 1);
    -moz-box-shadow: 0px 5px 9px 0px rgba(173, 173, 173, 1);
    box-shadow: 0px 5px 9px 0px rgb(218 218 218);
}

.navbar-default {
    background-color: white;
}

.navbar-brand img {
    width: 100px;
    height: 35px;
    margin: 5px 15px 0 20px;
}

.navbar-default .navbar-toggle {
    margin-top: -50px;
}
.nav li  a{
    padding: 10px 20px;
    color: #117fed;
}

.nav li  a:hover{
    color: #117fed;
}
.navbar-right{
    margin-top: 20px;
}
@media screen and (max-width: 768px) {
    .nav.login-nav li {
        float: right;
        margin: 0 0;
    }
    .navbar.navbar-default.navbar-static-top {
        height: 70px;
    }

    ul.login-nav {
        margin-top: -55px;
    }

    .nav li {
        background: white;
    }

    .navbar-header {
        height: 70px;
    }

    .navbar-right {
        margin-right: 0;
    }
}


@media (min-width: 1024px) {
    .navbar.navbar-default.navbar-static-top.navbar-main {
        height: 110px;
    }
    .nav li  a{
        padding: 10px 20px;
        color: #117fed;
    }

    .nav li  a:hover{
        color: #117fed;
    }

    .navbar-menu li {
        float: left;
        margin-top: 10px;
    }

    .navbar-header {
        height: 70px;
    }

    .navbar-right {
        margin-right: 0;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .navbar-menu li {
        float: left;
        margin-top: 10px;
    }

    .navbar-main .navbar-header {
        height: 115px;
    }

    .navbar-right {
        margin-right: 0;
    }

    .nav.login-nav li {
        float: right;
        margin: 15px 0 10px 0;
    }
}

.nav li a.active {
    border-bottom: 2px solid;
}
.spot-price-nav-bar {
    margin-top: 45px;
    top: 10px;
    left: 15px;
    position: absolute;
}

header .future-content {
    position: fixed;
    bottom: 0;
    height: 35px;
    width: 100vw;
    z-index: 210;
    background: white;
    color: black;
    border-top: 1px solid #aaa;
}

header .settlement-future-items {
    position: fixed;
    bottom: 35px;
    width: 100vw;
    z-index: 200;
    padding-left: 10px;
}

.future-content .settlement-feature-option {
    margin-top: 5px;
}

.settlement-future-items .future-item-title {
    background: #007fed;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    color: white;
    height: 30px;
    padding: 5px 10px;
}

.settlement-future-items .future-item-content {
    background: white;
    height: 60vh;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: height 0.2s linear;
    padding: 5px;
    overflow-y: auto;
}

.collapsed .future-item-content {
    height: 0;
    opacity: 0;
    padding: 0px;
    transition: height 0.2s linear;
    transition: opacity 0s linear;
}

.future-item .close-future-item {
    float: right;
    color: white;
}
