/* PSSpotPrice component style sheet */

.header-spot-prices {
    margin: 15px 20px 0px 20px;
    padding: 5px 10px;
    background-color: rgb(99 175 241 / 17%);
    border-radius: 3px;
}

.header-spot-prices .text-product {
    /* font-weight: bold; */
    color: #007fed;
    font-size: 14px;
}

.header-spot-prices .text-price {
    font-size: 13px;
}

.header-spot-prices .separator {
    color: rgb(180, 180, 180);
    margin-right: 10px;
}

.header-spot-prices .text-change {
    font-size: 13px;
}

@media (min-width: 768px) and (max-width: 1023px) {
    .header-spot-prices {
        font-size: 12px;
    }
}