/* PSLogin components Styles */
.card {
    background: rgb(253, 253, 253);
    /* background: linear-gradient(45deg, rgb(224, 224, 224) 23%, rgba(255,255,255,1) 52%, rgb(224, 224, 224) 82%); */
    box-shadow: 1px 14px 21px 1px rgba(122, 122, 122, 0.75);
    -webkit-box-shadow: 1px 14px 21px 1px rgba(167, 167, 167, 0.75);
    -moz-box-shadow: 1px 14px 21px 1px rgba(82,80,80,0.75);
}

.card-header-title {
    padding: 10px 0px 10px 20px;
    color: #319fdc;
}

.form-input-group{
    margin: 20px 40px 20px 40px;
    font-size: 20px;
}
.card .form-input {
    width: 100%;
}

.card .form-input input,
.card .form-input label {
    font-size: 15px;
}

.card-body .form-checkbox-input span{
    font-size: 15px;
}

.card-footer {
    margin: 15px 0px 15px 0px;
}

.card-footer .primary-btn {
    width: 100px;
    font-size: 13px;
    background: #319fdc;
    color: #fff;
    margin-bottom: 15px;
}

.card-footer .primary-btn:hover {
    background:  #267faf
}

.forget-password-link {
    margin-left: 15px;
    margin-top: 8px;
    font-size: 12px;
}

.alert-font .MuiAlert-message {
    font-size: 14px;
}

.login-panel {
    margin-top: 45px;
}