/* PSHome components Styles */
.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.option-table-content {
    overflow-x: auto;
}

.option-table .ant-table table {
    width: 100%;
    background-color: #FBFCFC;
}

.option-table .ant-table table td {
    padding: 3px 5px !important;
}

.ant-table-small .ant-table-thead>tr>th {
    background-color: #F0F3F4;
}

.dropzone {
    flex: 1;
    height: 90vh;
}

.option-table-cell {
    color: #ccc;
    font-size: 12px;
}

.option-table-strike-col {
    font-size:12px;
}

.ant-tooltip {
    max-width: 100%;
}

.option-table-tooltip {
    padding: 10px;
    font-size: 12px;
    position: relative;
}

.tooltip-space {
    padding: 0 10px;
}

.tooltip-divider {
    margin: 3px 0;
}

.ant-btn-primary {
    background-color: #007fed;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.option-table-title {
    background: #007fed;
    color: #FFFFFF;
    width: 90%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.option-table-title.first-table {
    /* margin-left: 50px; */
}

.atm-value {
    background: #84f784;
    font-weight: 500;
}

.atm-value .option-table-cell {
    color: black;
}

.semi-atm-value .option-table-call-col,
.semi-atm-value .option-table-put-col {
    background: #ffff19a8;
    color: black;
}

.qua-atm-value .option-table-put-col,
.up-qua-atm-value .option-table-call-col {
    background: rgb(62 165 255);
    color: black;
}

.far-atm-value .option-table-put-col,
.up-far-atm-value .option-table-call-col {
    background: rgb(83 173 251 / 61%);
    color: black;
}

.not-atm-value .option-table-put-col,
.up-not-atm-value .option-table-call-col {
    background: rgb(155 206 251 / 35%);
    color: black;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
    background: #ef444be0;
    color: white;
    cursor: pointer;
}

.main-divider {
    margin: 0 0 23px;
}

/* scroll bar style */
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(206, 206, 206);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(150, 150, 150);
}

.option-table-algin-container {
    display: flex;
    overflow: auto;
}

.search-result-panel {
    width: 600px;
}

.option-diff-table-row {
    background: #78d7f7;
}

.option-diff-table-row td {
    color: black;
}

.option-table-title-dropdown {
    background: #007fed;
    color: white;
    border: none;
    width: 100%;
}

.option-table-title-dropdown:hover,
.option-table-title-dropdown:focus {
    background: #359cf7;
    color: white;
}

.option-table-title span{
    font-size: 14px;
}

@media (max-width: 1440px) and (min-width:1366px) {
    .option-table-title span{
        font-size: 12px;
    }
}

@media (max-width: 1365px) and (min-width:768px) {
    .option-table-title span{
        font-size: 12px;
    }

    .option-table-title {
        width: 99%;
    }
}

